import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Row, Space, Card } from 'antd';

const { Content, Footer } = Layout;

import mbtiTest from '../../mbti_test';

const IndexPage = ({ location, data }) => {
  const result = location.pathname.split('/')[2];
  return (
    <Content>
      <Helmet>
        <title>스위디 - 나를 알아가는 시간</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        <link type="text/css" rel="stylesheet" href={"/css/layout.css"} />
        <link rel="apple-touch-icon" sizes="57x57" href="/icon/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/icon/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/icon/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/icon/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/icon/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/icon/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/icon/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/icon/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/icon/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/icon/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/icon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/icon/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/icon/favicon-16x16.png" />
        <link rel="manifest" href="/icon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/icon/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />

        <meta name="author" content="스위디 - 나를 알아가는 시간" />
        <meta name="description" content="나와 당신을 위한 소중한 사람들의 특별한 이야기" />

        <meta property="og:title" content="스위디 - 나를 알아가는 시간" />
        <meta property="og:description" content="나와 당신을 위한 소중한 사람들의 특별한 이야기" />
        <meta property="og:image" content="https://sweedy.app/images/meta-image.png" />

        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=UA-127746049-3"></script> */}
        {/* <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'UA-127746049-3');
          `}
        </script> */}
      </Helmet>
      <Row justify="center" align="middle" style={{ paddingTop: 20 }}>
        <div>
          {result}
        </div>
      </Row>
    </Content >
  )
}

export default IndexPage;